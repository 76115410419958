import styled from 'styled-components';
import css from '@styled-system/css';

export const P = styled('p')(
  css({
    fontSize: [0, null, null, 1]
  })
);

export const PSmall = styled('p')(
  css({
    fontSize: 0
  })
);
